export default function Skilltec() {
    return (
        <>








            <div class="container px-4 py-5" id="skilltec">
                <div className="border p-5" data-aos="fade-up" >
                    <div class=" text-geral">
                        <h2 class="pb-2 border-bottom"> SKILL JOBS</h2>

                        <div className="row row-cols-lg-2">
                            <div className="col-md-2 d-flex mb-2" data-aos="zoom-in"  data-aos-delay="150" >
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                <p className="ms-0"> Adobe Photoshop</p>
                            </div>
                             <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="200">
                                <div className=" d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                <p className="ms-0"> Adobe Ilustrator</p>
                            </div>
                            <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="250">
                                <div className=" d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                <p className="ms-0">Figma</p>
                            </div>
                              <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="300">
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                 <p className="ms-0"> Integrações via Webhook </p>
                            </div>
                              <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="350">
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                 <p className="ms-0"> Automações de CRM</p>
                            </div>
                              <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="400">
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                 <p className="ms-0"> Gestão de Trafego(Meta Ads e Google Ads)</p>
                            </div>
                              <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="450">
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                 <p className="ms-0"> Active campaign</p>
                            </div>
                              <div className="col-md-2 d-flex mb-2"  data-aos="zoom-in"  data-aos-delay="500">
                                <div className="d-inline-flex m-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bg-icon mx-2" height="36" fill="#c12437" viewBox="0 -960 960 960" width="36"><path d="M180-120q-24.75 0-42.375-17.625T120-180v-600q0-24.75 17.625-42.375T180-840h600q14 0 25.5 6t18.5 14l-44 44v-4H180v600h600v-343l60-60v403q0 24.75-17.625 42.375T780-120H180Zm281-168L239-510l42-42 180 180 382-382 42 42-424 424Z" /></svg>

                                </div>
                                 <p className="ms-0"> Inglês Técnico Intermediário</p>
                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}