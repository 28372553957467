import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import './styles.css';

// import required modules
import { EffectCards } from 'swiper/modules';

export default function CarouselSite() {
    return (
        <>
            <section id="features" class="features" data-aos="fade-up" data-aos-duration="1000">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-6 order-1 order-lg-2" >
                            <div class="icon-box mt-5 mt-lg-5 mt-5" data-aos="fade-right" data-aos-duration="300">
                                <i className='me-2'><img src="https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/imagens/logo02" height="50" styled={{ opacity: "0.85" }} /></i>
                                <h2 className="fw-bold ">Páginas de Alta Performance e Conversão</h2>
                                <h4 className='text-geral fw-light'>Todos os meus sites são feito de forma estratégica para ter os melhores resultados na rede de pesquisa do google e SEO, com google analytics instalado para acompanhar a performance em tempo real. Realizo todas as configurações do sitemap no Google Search Console.E o grande diferencial que alguns sites, prefiro desenvolver com Reactjs e Nodejs uma tecnologia utilizada por grandes empresas como Instagram, Airbnb e Netflix por ser uma biblioteca que permite criar aplicações web mais rápidas e responsivas.</h4>
                                <h4>Link de Sites Desenvolvidos</h4>
                                <ol className='ms-5'>
                                    <li><a target='blank' href='https://netsimtelecom.com.br'> Netsim Telecom <Icon icon="catppuccin:url" /></a></li>
                                    <li><a target='blank' href='https://startecmontagem.com.br'>Startec Montagem <Icon icon="catppuccin:url" /></a></li>
                                    <li><a target='blank' href='https://jofranimoveis.com.br'> Jofran Imóveis <Icon icon="catppuccin:url" /></a></li>
                                    <li><a target='blank' href='https://bellacestaofc.com.br'> Bella Cesta <Icon icon="catppuccin:url" /></a></li>
                                    <li><a target='blank' href='https://promovaonline.com.br'> Promova Online <Icon icon="catppuccin:url" /></a></li>
                                </ol>
                            </div>

                        </div>
                        <div class="col-lg-6 order-2 order-lg-1 px-5">
                            <Swiper

                                loop={true}

                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                effect={'cards'}
                                grabCursor={true}
                                modules={[EffectCards, Pagination, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/bellacestas-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/netsim-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/netsim-2.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/startec-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/jofran-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>
                                <SwiperSlide><img src="https://tvaebmtangjwdgtoxvdj.supabase.co/storage/v1/object/public/imagens/sites/promova-1.png?t=2024-10-08T21%3A23%3A15.455Z" width="70%" height="auto" /></SwiperSlide>

                            </Swiper>
                        </div>
                    </div>

                </div>
            </section>


        </>
    );
}