export default function SobreMim() {
    let dataNascimento = new Date(1999, 9, 2);
    let dataAtual = new Date();

    let diferenca = dataAtual.getTime() - dataNascimento.getTime();
    let idade = Math.floor(diferenca / (1000 * 60 * 60 * 24 * 365.25));

    console.log(idade);
    return (

        <div className="container" id="sobremim">
            <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div class="col-10 col-sm-8 col-lg-6" data-aos="fade-up" data-aos-delay="400">
                    <h1 class="display-5 fw-bold text-light lh-1 mb-3">Quem é?</h1>
                    <p class="lead text-geral text-light">
                        Tem <b className="fw-bold text-red">{idade} anos</b> nasceu em 02/09/1999, natural de <b className="fw-bold text-red">Brasilia-DF</b>,
                        <b className="fw-bold text-red"> Casado</b>,tirou a <b className="fw-bold text-red">CNH AB</b> em 2022 , é formado com <b className="fw-bold text-red">Ensino Médio Completo</b>, terminou o Curso de <b className="fw-bold text-red"> Design Gráfico</b> em 2016 e <b className="fw-bold text-red">Técnico em informática</b> em 2018. Expertise em marketing digital desde 2018. Mora no <b className="fw-bold text-red">Condomínio Residencial São francisco</b>, Recanto da Emas, Brasilia DF, CEP 72669-000.
                    </p>
                </div>
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="600">
                    <img src="https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/imagens/minhaImagem" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
                </div>
            </div>
        </div>

    )
}