import './banner.css'
import Header from '../Header';

export default function Banner() {
    return (
        <>
        <Header className="m-0"/>

<div class="main-banner">
    <div class="div-spacing">
        <div className='row'>
            <div className='col'>
                    <p class="lead my-3 text-geral text-light spacing" data-aos="fade-up" data-aos-delay="400">Eu sou <b className='text-red'>Natanael Alves da Costa</b>, e bem vindo ao meu portfólio profissional, espero que goste dos meus trabalhos, e desenvolvimento profissional.</p>
            </div>
            <div className='col'>
                
            </div>
        </div>
    </div>
  </div>

        </>
    )
}